<template>
  <v-container fluid>
    <v-layout>
      <v-container>
        <v-card>
        <v-card-title>Impressum</v-card-title>
          <v-card-subtitle>
          Anbieterangaben gem. § 5 TMG<br/>
          </v-card-subtitle>
          <v-card-text>
            <h4>Fußball-Club St. Pauli v. 1910 e.V.</h4>
        – Abteilung: Marathon –<br/>
        Harald-Stender-Platz 1<br/>
        20359 Hamburg<br/>
        Telefon: 040 - 31 78 74 0<br/>
        E-Mail: abteilungsleitung@fcstpauli-marathon.de<br/>
            Der Fußball-Club St. Pauli v. 1910 e.V. wird vertreten durch den Vorstand<br/>
        Oke Göttlich (Präsident), Christiane Hollander, Esin Rager, Carsten Höltkemeyer, Jochen Winand (Vizepräsident*innen)<br/>
        Registergericht: Amtsgericht Hamburg<br/>
        Vereinsregisternummer: VR1884<br/>
          </v-card-text>
        </v-card>
      </v-container>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: 'Impressum'
};
</script>
